import React from "react"
import { Link } from "gatsby"

import { Layout, SEO } from "../components"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="wrapper-small">
      <h1>404</h1>
      <h3>Page not found </h3>
      <p>
        The link that brought you here may be broken, or this page might have
        been removed.
      </p>
      <Link to="/">Back to Home</Link>
    </section>
  </Layout>
)

export default NotFoundPage
